import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/circleci/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "organisations"
    }}>{`Organisations`}</h1>
    <h2 {...{
      "id": "introduction"
    }}>{`Introduction`}</h2>
    <p>{`Organisation register ("organisations") is a service for storing and retrieving organisations referred to by Entur services.`}</p>
    <p>{`The core model is based on the Netex standard: `}<a parentName="p" {...{
        "href": "http://www.netex-cen.eu/model/conceptual/framework/index.htm?goto=4:5:248"
      }}>{`http://www.netex-cen.eu/model/conceptual/framework/index.htm?goto=4:5:248`}</a>{`.`}</p>
    <p>{`In addition to implementing the Netex model the service provides a few extensions:`}</p>
    <ul>
      <li parentName="ul"><em parentName="li">{`references`}</em>{` - additional ids and keys for the ogranisation`}</li>
      <li parentName="ul"><em parentName="li">{`generic extensions`}</em>{` - schemaless json-based additional information`}</li>
      <li parentName="ul"><em parentName="li">{`additional details for contact information`}</em>{` of core people of the organisation.`}</li>
    </ul>
    <h2 {...{
      "id": "model"
    }}>{`Model`}</h2>
    <p><img alt="Organisation Model" src={require("./diagrams/org-model.png")} /></p>
    <h2 {...{
      "id": "usage-context"
    }}>{`Usage Context`}</h2>
    <p>{`Organisation API is used by Team Sales, Team Customers, Team Route, Entur Partner WebUI, and Mobile & Web Apps.`}</p>
    <p><img alt="Org API - usage context" src={require("./diagrams/context.png")} /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      